<template>
  <v-col sm="12">
    <button
      class="tw-flex tw-items-center tw-mt-6 overview-btn"
      @click="selectTab"
    >
      <img src="@/assets/icons/terminal-arrow-back.svg" alt="" />
      <span class="tw-ml-4">All Trips</span>
    </button>

    <v-col
      sm="12"
      class="tw-bg-white tw-mt-9 tw-rounded-xl tw-pl-16 tw-py-10 tw-pr-40 tw-w-full"
    >
      <div
        class="tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"
      >
        <p class="edit-header">Edit Trip</p>
        <img src="@/assets/icons/del-blue.svg" alt="" />
      </div>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Date</p>
            <p class="label-info">Choose the date for the trip</p>
          </div>
        </v-col>
        <v-col sm="4" class="">
          <ValidationProvider
            name="First Name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="fromDateDisp"
                    v-on="on"
                    placeholder="2022-09-9"
                    solo
                    hide-details
                  >
                    <template #prepend-inner>
                      <v-icon :color="companyColor" class="">
                        mdi-calendar-month-outline
                      </v-icon>
                    </template></v-text-field
                  >
                </template>

                <v-date-picker
                  locale="en-in"
                  v-model="fromDateVal"
                  no-title
                  @input="fromDateMenu = false"
                  :min="minDate"
                ></v-date-picker>
              </v-menu>
              <!-- <v-text-field
                placeholder="Mason"
                solo
                hide-details
                type="text"
                required
              ></v-text-field> -->
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Time</p>
            <p class="label-info">Choose the time for the trip</p>
          </div>
        </v-col>
        <v-col sm="4" class="">
          <ValidationProvider
            name="Time"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="time"
                    v-on="on"
                    placeholder="6:00AM"
                    solo
                    hide-details
                  >
                    <template #prepend-inner>
                      <v-icon :color="companyColor" class="">
                        mdi-clock-outline
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="time"
                  ampm-in-title
                  full-width
                  @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>

              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Fleet</p>
            <p class="label-info">
              Select the fleet for the trip.
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Fleet"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                :items="fleets"
                label="Choose Fleet"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                outlined
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Route</p>
            <p class="label-info">
              Select the route for the trip.
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Route"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                :items="routes"
                label="Choose Route"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                outlined
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Status</p>
            <p class="label-info">
              Select the status for the trip.
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Status"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                :items="status"
                label="Choose Status"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                outlined
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Price</p>
            <p class="label-info">Fill in the price for the trip .</p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Price"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="tw-pt-1 mb-1 mt-2" :class="classes">
              <v-text-field
                append-icon="mdi-chevron-down"
                placeholder="15,000"
                solo
                hide-details
                type="text"
                v-model="city"
                required
                class="tw-mt-2"
                prefix="₦"
              >
              </v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <div
        class="tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"
      >
        <button class="first-btn">
          Cancel
        </button>
        <button class="second-btn">
          Done
        </button>
      </div>
    </v-col>
  </v-col>
</template>

<script>
import {} from "../../../services/api/APIService";
export default {
  name: "EditTrip",
  data() {
    return {
      time: null,
      menu2: false,
      modal2: false,
      fromDateMenu: false,
      fromDateVal: null,
      minDate: "2020-01-05",
      maxDate: "2019-08-30",
      companyColor: "#004AAD",
      routes: [{ name: "ABJ - KAD" }],
      fleets: [{ name: "BUS - KYK" }],
      status: [{ name: "pending" }, { name: "active" }],
    };
  },
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
  },
  methods: {
    selectTab() {
      this.$store.dispatch("tripComponent/setCurrentComponent", "allTrips");
      sessionStorage.setItem("tripComponent", "allTrips");
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}
.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
