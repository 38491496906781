<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import AllTrips from "./AllTripDashboard";
import SingleTrip from "./SingleTrip";
import EditTrip from "./EditTrip";
import AddTrip from "./AddTrip";
import { mapGetters } from "vuex";

export default {
  name: "ViewTripDashboardRoute",
  computed: {
    ...mapGetters("tripComponent", ["currentComponent"]),
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "allTrips") {
        return AllTrips;
      } else if (component === "singleTrip") {
        return SingleTrip;
      } else if (component === "editTrip") {
        return EditTrip;
      } else if (component === "addTrip") {
        return AddTrip;
      }
    },
  },
  created() {
    let component = sessionStorage.getItem("tripComponent");

    if (!component) {
      sessionStorage.setItem("tripComponent", "allTrips");
      this.$store.dispatch("tripComponent/setCurrentComponent", "allTrips");
      this.displayTerminalComponent();
    } else {
      this.$store.dispatch("tripComponent/setCurrentComponent", component);
      this.displayTerminalComponent();
    }
  },
};
</script>

<style lang="scss" scoped></style>
