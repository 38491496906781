var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 overview-btn",on:{"click":_vm.selectTab}},[_c('img',{attrs:{"src":require("@/assets/icons/terminal-arrow-back.svg"),"alt":""}}),_c('span',{staticClass:"tw-ml-4"},[_vm._v("All Trips")])]),_c('v-col',{staticClass:"tw-bg-white tw-mt-9 tw-rounded-xl tw-pl-16 tw-py-10 tw-pr-40 tw-w-full",attrs:{"sm":"12"}},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"},[_c('p',{staticClass:"edit-header"},[_vm._v("Edit Trip")]),_c('img',{attrs:{"src":require("@/assets/icons/del-blue.svg"),"alt":""}})]),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Date")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the date for the trip")])])]),_c('v-col',{attrs:{"sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.fromDateDisp,"placeholder":"2022-09-9","solo":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v(" mdi-calendar-month-outline ")])]},proxy:true}],null,true)},on))]}}],null,true),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"locale":"en-in","no-title":"","min":_vm.minDate},on:{"input":function($event){_vm.fromDateMenu = false}},model:{value:(_vm.fromDateVal),callback:function ($$v) {_vm.fromDateVal=$$v},expression:"fromDateVal"}})],1),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Time")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the time for the trip")])])]),_c('v-col',{attrs:{"sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"placeholder":"6:00AM","solo":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v(" mdi-clock-outline ")])]},proxy:true}],null,true),model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"ampm-in-title":"","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Fleet")]),_c('p',{staticClass:"label-info"},[_vm._v(" Select the fleet for the trip. ")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Fleet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"items":_vm.fleets,"label":"Choose Fleet","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","outlined":""}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Route")]),_c('p',{staticClass:"label-info"},[_vm._v(" Select the route for the trip. ")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Route","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"items":_vm.routes,"label":"Choose Route","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","outlined":""}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Status")]),_c('p',{staticClass:"label-info"},[_vm._v(" Select the status for the trip. ")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"items":_vm.status,"label":"Choose Status","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","outlined":""}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Price")]),_c('p',{staticClass:"label-info"},[_vm._v("Fill in the price for the trip .")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{staticClass:"tw-mt-2",attrs:{"append-icon":"mdi-chevron-down","placeholder":"15,000","solo":"","hide-details":"","type":"text","required":"","prefix":"₦"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"},[_c('button',{staticClass:"first-btn"},[_vm._v(" Cancel ")]),_c('button',{staticClass:"second-btn"},[_vm._v(" Done ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }