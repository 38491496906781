<template>
  <div
    :style="{ width: width, height: height }"
    style="border-radius: 10px; background: #FDFFFC; position: relative;"
    class="tw-flex tw-flex-row tw-p-5 tw-items-center tw-cursor-pointer tw-mt-2 lg:tw-mt-0"
    @click="$emit('clickAction')"
  >
    <div class="border-blue"></div>
    <img :src="icon" alt="icon" class="icon tw-ml-4" v-if="showImage" />
    <div class="tw-flex tw-w-auto tw-flex-col tw-ml-3">
      <label class="title" >{{ title }}</label>
      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between ">
        <label class="desc tw-mt-3 tw-mr-2 text-capitalize"
          >{{ labelOne }}<span v-if="valueOne">{{ valueOne }}</span>
        </label>
        <label class="desc tw-mt-3 tw-mr-2 text-capitalize"
          >{{ labelTwo }}<span class="tw-mt-3" v-if="valueTwo">{{ valueTwo }}</span>
        </label>
        <label class="desc tw-mt-3 tw-mr-2 text-capitalize"
          >{{ labelThree }}<span v-if="valueThree">{{ valueThree }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageAndTextTwo",
  props: {
    labelOne: [String],
    labelTwo: [String],
    labelThree: [String],
    showImage: {
      type: Boolean,
      default: true,
    },
    icon: [String],
    title: [String],
    valueOne: [String],
    valueTwo: [String],
    valueThree: [String],
    width: {
      type: [String],
      default: "191px",
    },
    height: {
      type: [String],
      default: "100px",
    },
  },
};
</script>

<style scoped lang="scss">
.border-blue {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #7baef2;
  box-shadow: 0px 4px 40px rgba(79, 79, 79, 0.02);
  left: 0;
  top: 0;
  bottom: 0;
  width: 14px;
  position: absolute;
}
.icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.title {
  font-family: "Inter", serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
  color: #4f4f4f;
  text-transform: capitalize !important;
}
.desc {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 10px;
  color: #4f4f4f;
  span {
    font-weight: 300;
  }

  @media screen and (max-width: 768px) {
    line-height: 24px !important;
  }
}
</style>
